import { Typography } from "@material-ui/core";
import { FC } from "react";

const NotSupport: FC = (props) => {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        The subscription model is no longer supported
      </Typography>
      <Typography variant="body1" gutterBottom>
        In case you are interested in the subscription model, it is still
        possible by contacting our Sales team.
      </Typography>
      <Typography variant="body1">
        To activate the 30-day trial license, just log in.
      </Typography>
    </>
  );
};

export default NotSupport;
