import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LocalizationProvider from './localization/LocalizationProvider';
import { CssBaseline } from '@material-ui/core';
import { Waiter } from 'react-wait';
import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH0_CONFIG } from './config';
import { BrowserRouter as Router } from "react-router-dom"


ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider>
      <CssBaseline>
        <Waiter>
          <Router>
            <Auth0Provider
              domain={AUTH0_CONFIG.domain}
              clientId={AUTH0_CONFIG.clientId}
              audience={AUTH0_CONFIG.audience}
              redirectUri={window.location.origin}
            >
              <App />
            </Auth0Provider>
          </Router>
        </Waiter>
      </CssBaseline>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
