import { createContext } from 'react';


export const languages = ['en-US', 'ja-JP', 'en-US', 'de-DE', 'zh-Hans', 'cs-CZ'] as const
export type Languages = typeof languages[number]

export type Localization = {
  t: (key: string, ...params: (string | number)[]) => string
  change: (lang: Languages) => void,
  current: Languages
};

export const LocalizationContext = createContext<Localization>({
  t: (key, ...params: (string | number)[]) => key,
  change: () => {},
  current: 'en-US'
});

