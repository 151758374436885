import { useAuth0 } from "@auth0/auth0-react"
import { createStyles, LinearProgress, makeStyles } from "@material-ui/core"
import { useWait } from "react-wait"

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'fixed',
            top: 64,
            left: 0,
            width: '100%',
            height: 'calc(100vh - 64px)',
            backgroundColor: '#ffffff4f',
            zIndex: 1
        }
    }),
);

const Loader = () => {
    const classes = useStyles()
    const { anyWaiting } = useWait()
    
    const {
        isLoading,
    } = useAuth0();

    if (!anyWaiting() && !isLoading) {
        return null 
    }

    return (
        <div className={classes.root}>
            <LinearProgress color="secondary" />
        </div>
    )
}

export default Loader