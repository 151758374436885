import React, {FC, useState} from "react";
import {Languages, LocalizationContext} from "./localizationContext"
import en from './texts/en-US.json';
import cs from './texts/cs-CZ.json';


export const texts: { [key: string]: { [key: string]: string } } = {'ja-JP': en, 'en-US': en, 'de-DE': en, 'zh-Hans':en, 'cs-CZ':cs} as const

type Props = {}

const LocalizationProvider: FC<Props> = (props) => {
  const [language, setLanguage] = useState<Languages>('en-US')
  const t = (key: string, ...params: (string | number)[]) => {
    let m = texts[language]?.[key] || texts['en-US']?.[key] || key
    if (params.length === 0) {
      if (params && params.length > 0) {
        for (let i = 0; i < params.length; i++) {
          const r = `{${i}}`;
          m = m.replace(r, params[i].toString());
        }
      }
    }
    return m
  }
  return (
    <LocalizationContext.Provider
      value={{
        t, change: setLanguage, current: language
      }}
    >
      {props.children}
    </LocalizationContext.Provider>
  )
}

export default LocalizationProvider
