export const DEV_SERVER_URL = 'http://localhost:5001/pekat-subscriptions/us-central1'
export const SERVER_URL = process.env.NODE_ENV === 'development' ? DEV_SERVER_URL : window.location.origin

export const STRIPE_CONFIG = {
    publishKey: 'pk_live_51IvhWhGehQKvzeO4JsrtonWKmSAZ6cBFQHlv1vxyBr0pGVLx8Ov3gtRb1GyFHLlgQdHc7D616UTGbim9PLt7rPYY00EMAcA24Z',
}

export const AUTH0_CONFIG = {
    domain: "dev-c6jv8lns.eu.auth0.com",
    clientId: "WddhqO8YD7sZinlANyq3TMpmNWdfIkdP",
    audience: "https://dev-c6jv8lns.eu.auth0.com/api/v2/",
    scope: "read:current_user",
}
