import React, { useEffect } from "react";
import {
  Container,
  createStyles,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core";
import Login from "./pages/Login";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Loader from "./components/Loader";
import Settings from "./pages/Settings";
import qs from "querystringify";
import useLocalization from "./localization/useLocalizaton";
import { languages } from "./localization/localizationContext";
import { useUserForm } from "./utils/server";
import NotSupport from "./pages/NotSupport";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      //marginTop: -64,
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      minHeight: "calc(100vh - 64px)",
    },
  })
);

function App() {
  const classes = useStyles();
  const localization = useLocalization();
  const userForm = useUserForm();

  useEffect(() => {
    const { lang, provider } = qs.parse((window as any).location.search) as any;
    const storedLang = localStorage.getItem("language");

    if (lang && languages.includes(lang)) {
      // set language from URL
      localStorage.setItem("language", lang);
      localization.change(lang);
    } else if (storedLang && languages.includes(storedLang as any)) {
      // set language from local storage
      localization.change(storedLang as any);
    }

    if (provider) {
      localStorage.setItem("provider", provider);
    }
  }, []);

  useEffect(() => {
    if (userForm.data) {
      localization.change(userForm.data.language);
    }
  }, [userForm.data]);

  return (
    <>
      <Header />
      <Container>
        <Paper className={classes.paper} square>
          <Loader />
          <NotSupport />
          {/* <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/settings">
              <Settings userForm={userForm} />
            </Route>
            <Route path="/">
              <Home userForm={userForm} />
            </Route>
          </Switch> */}
        </Paper>
      </Container>
    </>
  );
}

export default App;
