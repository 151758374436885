import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react"
import { Product, Subscription, UserFormValues } from "../types"
import { loadStripe } from '@stripe/stripe-js';
import { SERVER_URL, STRIPE_CONFIG } from "../config";
import { useWait } from "react-wait";

export const useProducts = () => {
    const [product, setProduct] = useState<null | Product>(null)
    const [subscription, setSubscription] = useState<null | Subscription | undefined>()
    const { startWaiting, endWaiting } = useWait();

    const {
        user,
        getAccessTokenSilently,
    } = useAuth0();

    useEffect(() => {
        if (user) {
            startWaiting('subscriptions')
            getAccessTokenSilently()
                .then(token =>
                    fetch(`${SERVER_URL}/api/products`, {
                        method: 'post',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'application/json'
                        })
                    }))
                .then((res) => res.json())
                .then((data) => {
                    setProduct(data.items)
                    setSubscription(data.subscription)
                })
                .finally(() => {
                    endWaiting('subscriptions')

                })
        }
    }, [user, getAccessTokenSilently])

    const createSubscription = useCallback(async () => {
        startWaiting("createSubscription")
        const stripe = await loadStripe(STRIPE_CONFIG.publishKey)
        getAccessTokenSilently().then(token =>
            fetch(`${SERVER_URL}/api/createSubscribe`, {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({})
            })
        )
            .then((res) => res.json())
            .then(({ sessionId }) => stripe?.redirectToCheckout({ sessionId }))
            .finally(() => endWaiting("createSubscription"))
    }, [getAccessTokenSilently])

    const redirectToPortal = useCallback(() => {
        startWaiting('redirectToPortal')
        getAccessTokenSilently().then(token =>
            fetch(`${SERVER_URL}/api/customer_portal`, {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                }),
            })
        )
            .then((res) => res.json())
            .then(({ url }) => {
                endWaiting('redirectToPortal')
                window.location.assign(url)
            })
    }, [getAccessTokenSilently])

    /*const activeTrial = useCallback(() => {
        startWaiting('activeTrial')
        getAccessTokenSilently().then(token =>
            fetch(`${SERVER_URL}/api/activeTrial`, {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                }),
            })
        )
        .then((res) => res.json())
        .then((data) => {
            setProduct(data.items)
            setSubscription(data.subscription)
        })
        .finally(() => {
                endWaiting('activeTrial')
            })
    }, [getAccessTokenSilently, startWaiting, endWaiting])
    */

    return { product, subscription, createSubscription, redirectToPortal }
}

export const useUserForm = () => {
    const { startWaiting, endWaiting, ...other } = useWait()

    const [data, setData] = useState<null | undefined | UserFormValues>()

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    useEffect(() => {
        if (user) {
            getForm()
        }
    }, [user])

    const setForm = useCallback((formData: UserFormValues) => {
        startWaiting("setUserData")
        return getAccessTokenSilently().then(token =>
            fetch(`${SERVER_URL}/api/set_user_data`, {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(formData)
            }).then((data) => {
                return data.json()
            })
                .finally(() => {
                    endWaiting("setUserData")
                })
                .then(data => {
                    if (data.status === 'success') {
                        setData(formData)
                    }
                })
        )
    }, [])


    const getForm = useCallback(() => {
        startWaiting("getUserData")
        getAccessTokenSilently().then(token =>
            fetch(`${SERVER_URL}/api/get_user_data`, {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }),
            }).then((data) => {
                return data.json()
            }).then(data => {
                endWaiting("getUserData")
                if (data.status === 'success') {
                    setData(data.userForm)
                }
            })
        )
    }, [])

    return { setForm, data }
}
