import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useAuth0 } from '@auth0/auth0-react';
import logoImg from './logo_dark.svg'
import { Container, Link } from '@material-ui/core';
import useLocalization from '../localization/useLocalizaton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: 'relative'
    },
    appBar: {
      backgroundColor: '#23272b',
    },
    logo: {
      height: 30
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    offset: {
      flexGrow: 1,
    },
    name: {
      marginRight: theme.spacing(1),
      color: '#1abae7'
    },
    title: {
      marginLeft: theme.spacing(2),
      fontWeight: 300
    }
  }),
);

export default function Header() {
  const classes = useStyles();

  const {
    isAuthenticated,
    logout,
    user,
    error,
  } = useAuth0();

  const { t } = useLocalization()
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>

        <Container>
          <Toolbar disableGutters>
            <img src={logoImg} className={classes.logo} alt="logo" />
            <Typography variant="h5" className={classes.title}>{t('header.subscription')}</Typography>
            <div className={classes.offset} />
            {user && <Link className={classes.name} to={"/settings"} component={RouterLink} variant="body2">
              {user.name}
            </Link>}
            {(isAuthenticated || (error as any)?.error === 'unauthorized') && <Button color="inherit" onClick={() => logout({ returnTo: window.location.origin })}>{t('header.logout')}</Button>}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
