import {LocalizationContext} from "./localizationContext";
import {useContext} from "react";

const useLocalization = () => {
  const { t, change, current } = useContext(LocalizationContext)
  return {
    t, change, current
  }
}

export default useLocalization
